html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  background-color: #171210;
  color: white;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

.container {
  display: flex;
  flex-direction: row;
}

nav {
  width: 200px;
}

.SketchComponent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.s-container {
  display: flex;
}

.left-section {
  flex: 1; /* Take up all available space */
}

.right-section {
  width: 300px; /* Adjust the width as needed */
  padding: 10px; /* Add some spacing if desired */
  font-family: monospace;
}
